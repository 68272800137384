<template>
    <div>
        <v-container>
            <v-row>
                <div class="col-md-12 col-sm-12">
                    <v-card dense>
                        <v-card-title>
                            <h3>{{ "Reingresos" }}</h3>
                        </v-card-title>
                        <v-card-text>
                            <v-row>
                                <div class="col-md-12 col-sm-12">
                                    <v-card dense >
                                        <v-row  style="margin: auto;">
                                            <v-col>
                                                <s-select-definition
                                                    :def="1363"
                                                    label="Tipo de parihuela"
                                                    v-model="itemHead.TypePallet"
                                                    clearable
                                                />
                                            </v-col>
                                            <v-col>
                                                <s-select-definition
                                                    :def="1450"
                                                    label="Planta Procedencia"
                                                    v-model="itemHead.CdtWareHouses"
                                                    clearable
                                                >
                                                </s-select-definition>
                                            </v-col>
                                            <v-col>
                                                <v-btn
                                                    fab
                                                    x-small
                                                    color="success"
                                                    @click="CreatePallet()">
                                                    <i class="fas fa-plus"></i>
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                        <v-divider ></v-divider>
                                        <v-row style="margin: auto;">		
                                            <v-col
                                                cols="6"
                                                lg="3"
                                                md="4"
                                                :key="col.PdlID"
                                                v-for="col in itemPallets"
                                            >
                                                <v-badge color="white">
                                                    <template v-slot:badge>							 
                                                        <v-btn	
                                                            style="margin-left: -29px;margin-top: 2px;"
                                                            fab
                                                            small
                                                            color="white"
                                                            @click="removePallet(col)"
                                                        >
                                                            <v-icon 
                                                                color="error" 
                                                                class="fas fa-trash"
                                                                small>
                                                            </v-icon>
                                                        </v-btn>								 
                                                    </template>
                                                    <v-card >								 
                                                        <v-card-title style="background: #eaeaea;">
                                                            <span class="text-h6 font-weight-light">
                                                                <!-- {{ col.TypeDiscartN }} -->
                                                            </span>
                                                        </v-card-title>
                                                        <v-card-text style="display: grid; background: #eaeaea;"> 
                                                            <v-img
                                                                lazy-src="../../../../assets/pallets44.png"
                                                                max-height="200"
                                                                max-width="200"
                                                                src="../../../../assets/pallets44.png"
                                                                ><v-btn
                                                                    style="border-radius: 100px; height: 70px; width: 70px; margin: auto; display: flex; justify-content: space-evenly; "
                                                                    color="info"
                                                                    @click="openModal(col)"
                                                                >
                                                                    <v-row justify="space-around" no-gutters>
                                                                        <v-icon class="mdi-48px" >mdi-clipboard-text</v-icon>
                                                                    </v-row>
                                                                   
                                                                </v-btn>
                                                            </v-img>
                                                        </v-card-text>
                                                        <v-card-text class="text-center">
                                                            
                                                                <b>Tipo Pallet : </b> {{col.TypePalletName}}<br>
                                                                 <b>Planta : </b> {{col.TypePlantName}}<br>

                                                                <!-- <v-col cols="12" lg="12" md="12" sm="12" >
                                                                    <v-btn
                                                                        outlined
                                                                        small
                                                                        elevation="3"
                                                                        color="success"
                                                                        style="width:100%"
                                                                        @click="clickSend(col)"
                                                                    >
                                                                        Enviar
                                                                    </v-btn>
                                                                </v-col> -->
                                                            
                                                        </v-card-text>																
                                                    </v-card>	
                                                </v-badge>
                                            </v-col>
                                        </v-row>
                                    </v-card>
                                </div>
                            </v-row>



                            <!-- jhfdjfh -->
                            
                            <!-- <v-col cols="12" lg="12" md="12" sm="12">
                                <v-row style="margin-top: 0.1em;">
                                    <v-btn small left color="primary" dark @click="saveReEntry()" hide-details>
                                        <v-icon left small>fas fa-save</v-icon> Guardar Reingreso
                                    </v-btn>
                                </v-row>
                                
                            </v-col> -->
                           
                        </v-card-text>
                    </v-card>
                </div>
            </v-row>
        </v-container>
        <v-dialog
            v-model="ModalPalletReEntry"
            v-if="ModalPalletReEntry"
            persistent
            width="1500"
        >
            <re-entry-pallet :PalletSelected="PalletSelected" @close="close()"></re-entry-pallet>
        </v-dialog>
    </div>
</template>
<script>
    import ReEntryPallet from "./FrzChamberReEntryPallet.vue";

    import _sFrzChamberReEntry from "@/services/FrozenProduction/FrzChamberReEntry.js";
    import _sFrzCameraIncomePallet from "@/services/FrozenProduction/FrzCameraIncomePallet.js";

    export default{
        components: {ReEntryPallet},
        data(){
            return {
                itemHead :{

                },
                itemPallets : [],
                ModalPalletReEntry: false,
                PalletSelected : {},

                // CdtWarehouses: 0,
                // PppID: 0,
                // itemDispatch : [],
                // CdtWarehouses : 0,
                // itemsCamerasTunnels: [],
                // objTunnelCamera: {},
                // objDispatch: {}
            }
        },
        watch: {
            
        },
        methods: {
            CreatePallet(){
                if(this.itemHead.TypePallet == null){
					this.$fun.alert("Seleccione Pallet", "warning");
					return;
				}
				if(this.itemHead.CdtWareHouses == null){
					this.$fun.alert("Seleccion planta de Procedencia", "warning");
					return;
				}

                
                this.$fun.alert("Esta seguro de crear Pallet?", "question").then((r) => {
                    if (r.value) {
                        let obj = {
                            TypePallet : this.itemHead.TypePallet,
                            CdtWareHouses : this.itemHead.CdtWareHouses,
                            UsrCreateID : this.$fun.getUserID(),
                            
                        }
                        _sFrzChamberReEntry
                        .savePallet(obj, this.$fun.getUserID())
                        .then((r) => {
                            if (r.status == 200) {
                                this.$fun.alert("Registrado correctamente", "success");                
                                this.initialize();
                            }
                        });
                    }
                });
            },
            removePallet(item){
                
                this.$fun.alert("Esta seguro de eliminar Pallet?", "question").then((r) => {
                    if (r.value) {
                        let obj = {
                            RepID : item.RepID,
                            UsrUpdateID : this.$fun.getUserID(),
                            
                        }
                        _sFrzChamberReEntry
                        .removePallet(obj, this.$fun.getUserID())
                        .then((r) => {
                            if (r.status == 200) {
                                this.$fun.alert("Elimnado correctamente", "success");                
                                this.initialize();
                            }
                        });
                    }
                });
            },
            openModal(item){
                this.ModalPalletReEntry = true
                this.PalletSelected = item
            },
            clickSend(){

            },


            initialize (){
                _sFrzChamberReEntry.listPallet({}, this.$fun.getUserID()).then(r => {
                    if(r.status == 200)
                    {
                        this.itemPallets = r.data;
                        
                    }
                });


            },
            close(){
                this.ModalPalletReEntry = false
            }
            // changeChamber()
            // {
            //     console.log('lista de camaras')
            //     _sFrzCameraIncomePallet.getTunnelChamber({CdtWarehouses : this.CdtWarehouses},this.$fun.getUserID())
            //     .then(r =>{
            //         if(r.status == 200)
            //         {
            //             this.itemsCamerasTunnels = r.data;
            //         }
            //     });
            // },
            // saveReEntry(){
            //     console.log('save reentry');
            //     if(this.objDispatch.PppID == undefined){
            //         this.$fun.alert('Seleccione despacho','warning')
            //         return
            //     }
            //     if(this.CdtWarehouses == 0){
            //         this.$fun.alert('Seleccione almacen','warning')
            //         return
            //     }
            //     if(this.objTunnelCamera.CdtID == undefined){
            //         this.$fun.alert('Seleccione tunel','warning')
            //         return
            //     }
            //     this.$fun.alert("¿Seguro de Guardar?", "question")
            //     .then(r =>{
            //         if(r.value)
            //         {   
            //             let item = {
            //                 CdtID : this.objTunnelCamera.CdtID,
            //                 CdtWarehouses : this.CdtWarehouses,
            //                 PppID : this.objDispatch.PppID,
            //                 UsrCreateID : this.$fun.getUserID(),
            //             }
            //             console.log('send',item);
            //             // return                       
            //             _sFrzChamberReEntry
            //             .save(item, this.$fun.getUserID())
            //             .then(resp => {
            //                 if(resp.status == 200)
            //                 {
            //                     this.$fun.alert("Guardado correctamente", "success");
            //                     return;
            //                 }
            //             })
            //         }
            //     })
            // }


        },
        created()
        {
            this.initialize()   
        }
    }
</script>