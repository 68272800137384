<template>
	<div>
        <v-card class="m-0 p-0">
		    <v-col cols="12" lg="12" md="12" style="display: contents;" >
			    <template style="margin-top: 10px;">
                    <s-crud
                        title="Agregar Datos"
                        
                        :config="config"
                        :filter="filter"
                        add
                        @save="save($event)"
                        edit
                        remove
                        search-input
                        close
                        @close="close()"
                        ref="gridCreatePallet"
                    >
                        <template v-slot="props">
                            <v-container>
                                <v-row>
                                    <v-col cols="3" lg="3" md="6" sm="12" hidden>
                                        <s-text
                                            label="Id Pallet Reingreso"
                                            v-model="PalletSelected.RepID"
                                        >
                                        </s-text>
                                    </v-col> 
                                    <!-- {{ PalletSelected }} -->
                                    <v-col cols="3" lg="3" md="6" sm="12">
                                        <s-select-definition
                                            label="Cultivo"
                                            :def="1173"
                                            v-model="TypeCultive"
                                            @input="variety($event)"
                                        >
                                        </s-select-definition>
                                    </v-col> 
                                    <v-col cols="3" lg="3" md="6" sm="12">
                                        <s-select-definition
                                            label="Tipo Corte"
                                            :def="1429"
                                            v-model="props.item.TypeCut"
                                        >
                                        </s-select-definition>
                                    </v-col> 
                                    <v-col cols="3" lg="3" md="6" sm="12">
                                        <s-select
                                            :items="itemVariety"
                                            label="Variedad"
                                            item-text ="VrtDescription"
                                            item-value ="VrtID"
                                            v-model="props.item.VrtID"
                                        >
                                        </s-select>
                                    </v-col>
                                    <v-col cols="3" lg="3" md="6" sm="12">
                                        <s-text
                                            label="ID Trazabilidad"
                                            v-model="props.item.TraceabilityID"
                                        >
                                        </s-text>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="3" lg="3" md="6" sm="12"> 
                                        <s-select-client
                                            clearable
                                            label="Cliente"
                                            v-model="props.item.CumID"
                                            ref="txtCumID"
                                            full
                                        >
                                        </s-select-client>
                                    </v-col>
                                    <v-col cols="3" lg="3" md="6" sm="12"> 
                                        <s-select-brands
                                            clearable
                                            label="Marcas"
                                            v-model="props.item.CbdID"
                                            ref="txtBrands"
                                            :cumID="props.item.CumID"
                                            full
                                        >

                                        </s-select-brands>
                                        <!-- {{ props.item.CbdID }} -->
                                    </v-col>
                                    <v-col cols="3" lg="3" md="6" sm="12"> 
                                        <s-select-definition
                                            label="Tipo Empaque"
                                            v-model="props.item.TypePacking"
                                            :def="1435"
                                        ></s-select-definition>
                                    </v-col>     
                                    <v-col cols="3" lg="3" md="6" sm="12"> 
                                        <s-select-definition
                                            label="Tipo Cultivo"
                                            v-model="props.item.TypeCrop"
                                            :def="1172"
                                        ></s-select-definition>
                                    </v-col>                      
                                    <v-col cols="3" lg="3" md="6" sm="12"> 
                                        <s-select-definition 
                                            :def=1154 label="Estado" 
                                            v-model=props.item.PdcState>
                                        </s-select-definition>  
                                    </v-col>
                                    <v-col cols="3" lg="3" md="6" sm="12">
                                        <s-text
                                            v-model="props.item.NumberBoxes"
                                            label="N° cajas"
                                        >
                                        </s-text>
                                    </v-col>
                                    <v-col cols="3" lg="3" md="6" sm="12">
                                        <s-text
                                            v-model="props.item.WeightNetBoxes"
                                            label="Peso Caja(kg)"
                                        >
                                        </s-text>
                                    </v-col>
                                    <v-col cols="3" lg="3" md="6" sm="12">
                                        <s-switch
                                            v-model="props.item.IsObserved"
                                            label="Observado"
                                        >
                                        </s-switch>
                                    </v-col>
                                </v-row>
                                <v-row> 
                                    <v-col  cols="3" lg="3" md="6" sm="12" v-if="props.item.IsObserved == 1">
                                        <s-select-definition
                                            label = "Condicion"
                                            :def="1453"
                                            v-model="props.item.TypeObservation"
                                            clearable   
                                        >
                                        </s-select-definition>
                                    </v-col>
                                    <v-col  cols="6" lg="6" md="6" sm="12" v-if="props.item.IsObserved == 1">
                                        <s-textarea
                                            label = "Descripcion"
                                            v-model="props.item.CreObservation"
                                            clearable   
                                        >
                                        </s-textarea>
                                    </v-col>
                                </v-row>

                            </v-container>
                        </template>
                        <template v-slot:filter>
							<v-container>
								<v-row justify="center" class="s-col-form">
									<v-col justify="center" lg="3" cols="12"
										><s-date
											label="Fecha Inicio"
											v-model="filter.DateBegin"
										></s-date>
									</v-col>
									<v-col lg="3" cols="12"
										><s-date
											label="Fecha Fin"
											v-model="filter.DateEnd"
										></s-date>
									</v-col>
                                    <v-col hidden lg="3" cols="12"
										><s-text
											label="Fecha Fin"
											v-model="filter.RepID"
										></s-text>
									</v-col>
								</v-row>
							</v-container>
                            <!-- <div class="row">
                                <v-col cols="12">
                                    <s-toolbar
                                        dark
                                        :color="'#BAB6B5'"
                                        class="mr-4 ml-2"
                                        style="display: flex"
                                        print
                                        @print="printQR()"
                                    >
                                    
                                    </s-toolbar>
                                </v-col >
                            </div> -->
						</template>
                        <template v-slot:CreStatus="{row}">
                            <v-icon :color="row.CreStatus == 1 ? 'success': 'default'">mdi-checkbox-blank-circle</v-icon>
                        </template>
                        <template v-slot:IsObserved="{ row }">
                            <v-chip  x-small=""  :color="row.IsObserved == 1 ? 'error': 'info'" >
                                {{ row.IsObserved == 1 ? 'Observado': 'Ok' }}
                            </v-chip>
                            <!-- <v-chip v-else>
                                
                            </v-chip> -->
                        </template>
                        
                            
                    </s-crud>
                </template>
            </v-col>
        </v-card>
        <!-- <v-row justify="center" cols="12" style="display: none">
            <v-col cols="12" v-for="item in selected">
                <qr-code
                    :text="
                        item.PalletGroupID + ''
                        "
                    error-level="H"
                    :size="180"
                    hidden
                >
                </qr-code
                ><br />
            </v-col>
        </v-row>    -->
			

    </div>
</template>

<script>
	
    import _sFrzChamberReEntry from "@/services/FrozenProduction/FrzChamberReEntry.js";
    import SSelectClient from "@/components/FrozenProduction/Maturation/SSelectClienteMaturation";
    import SSelectBrands from "@/components/FrozenProduction/Chamber/SSelectBrandChamber";
    import _sFrzChamberCustomerBrandService from "@/services/FrozenProduction/FrzChamberCustomerBrandService.js";

    import qrCode from "vue-qr-generator";


    

	export default {
		props: {
            PalletSelected :{
                type : Object,
                required: false,
            },
		},
		components: {SSelectClient, qrCode,SSelectBrands},
		data: () => ({
			config:{
                model:{
                    CreID:"ID",
                    CreStatus: "CreStatus",
                    IsObserved: "IsObserved",
                },
                service: _sFrzChamberReEntry,
                headers:
                [
                    {text:"ID", value:"CreID"},
                    {text:"Fecha", value:"DateIncome"},
                    {text:"Hora", value:"HourIncome"},
                    {text:"N° Pallet", value:"PalletGroupID"},
                    {text:"ID Trazabilidad", value:"TraceabilityID"},
                    {text:"Cliente", value:"CumNombre"},
                    {text:"Marca", value:"CbdBrans"},
                    {text:"Variedad", value:"VrtDescription"},
                    {text:"Tipo Corte", value:"TypeCutName"},
                    {text:"Presentacion", value:"TypePackingName"},
                    {text:"N° Cajas", value:"NumberBoxes"},
                    {text:"Peso Neto(Kg)", value:"WeightNetPallet"},
                    {text:"Estado", value:"IsObserved"},
                    {text:"Condicion", value:"TypeObservationName"},
                    {text:"Detalle Condicion", value:"CreObservation"},
                    {text:"Estado", value:"CreStatus", align:"center", sorteable:true},  
                ]
            },
            filter: {
                DateBegin: null,
                DateEnd: null,       
                RepID :0             
            },
            itemVariety:[],
            TypeCultive : 0,
            // IsObserved : 0,
            TypeObservation : 0,
            CreObservation :'',


            // RepID: 0
        
		}),
       

		created() {

            this.filter.RepID = this.PalletSelected.RepID

		},

		methods: {
           
			close() {
				this.$emit("close");
                // this.$emit("refresh");
			},
			variety(item){
                console.log('item',item);
                _sFrzChamberReEntry.listVrt( {TypeCultive :item}, this.$fun.getUserID()).then(r => {
                    if(r.status == 200)
                    {
                        this.itemVariety = r.data;
                        console.log('variedda',this.itemVariety);
                    }
                });
            },
            save(item){
                item.TypeCultive = this.TypeCultive;
                item.RepID = this.PalletSelected.RepID;
                if(item.IsObserved == 0){
                    item.TypeObservation = 0;
                    item.CreObservation = ''
                }

                if(item.IsObserved == 1 && item.TypeObservation == 0){
                    this.$fun.alert('Seleccione Condicion','warning');
                    return
                }
                if(item.IsObserved == 1 && item.CreObservation ==''){
                    this.$fun.alert('Ingrese detalle de observacion','warning');
                    return
                }           
                console.log('itemss',item);     
                item.save().then((r) => {
                if (r.status == 200) {
                    this.$fun.alert("Registrado correctamente", "success");
                    this.$refs.gridCreatePallet.refresh();
                    // this.$emit("close");
                }
                });
            },
           
            // printQR() {           
            //         var imgAll = document.querySelector("img");
            //         console.log('imgAll ', imgAll);
            //         var Pagelink = "about:blank";
            //         var pwa = window.open(Pagelink, "_new");
            //         pwa.document.open();
            //         pwa.document.write(
            //             "<html><head><scri" +
			// 			"pt>function step1(){\n" +
			// 			"setTimeout('step2()', 10);}\n" +
			// 			"function step2(){window.print();window.close()}\n" +
			// 			"</scri" +
			// 			"pt><style  media='print'> body{font-family: arial} table, th, td {border: 1px solid black;border-collapse: collapse;padding:2px;font-size:9pt;}" + 
			// 			" @media print  { @page { margin-left: 0.5cm; {} </style></head><body onload='step1()'> \n" +
			// 			"<div class='col col-12 justify-center'>",
            //         );
            //             pwa.document.write(
            //                 "<div class='col col-6' style='margin: 20px 20px 20px 20px; position:absolute;'><img alt='Scan me!' style='display: block;'  src='" +
            //                     imgAll.currentSrc +
            //                     "' />",
            //             );
                            
            //             pwa.document.write("<b style='font-size: 35px!important; z-index: 2;margin-top: 40px;border-radius: 20px;'>ID: " + 
            //             this.selected[0].PalletGroupID+"</b>"); 
            //         pwa.document.write("</div></body></html>");
            //         pwa.document.close();
               
				
			// },
			
           


		},

       


			

			
		

		mounted(){
			
			 
		}
	};
</script>

