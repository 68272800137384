import Service from "../Service"

const resource = "FrzChamberReEntry/"

export default {

    // listDispatch(obj, requestID) {
    //     return Service.post(resource + "listDispatch", obj, {
    //       params: { requestID: requestID },
    //     });
    // },
    // save(obj, requestID) {
    //     return Service.post(resource + "save", obj, {
    //       params: { requestID: requestID },
    //     });
    // },
    savePallet(obj, requestID) {
        return Service.post(resource + "savePallet", obj, {
          params: { requestID: requestID },
        });
    },
    listPallet(obj, requestID) {
        return Service.post(resource + "listPallet", obj, {
          params: { requestID: requestID },
        });
    },
    removePallet(obj, requestID) {
        return Service.post(resource + "removePallet", obj, {
          params: { requestID: requestID },
        });
    },   
    pagination(obj, requestID) {
        return Service.post(resource + "pagination", obj, {
          params: { requestID: requestID },
        });
    },  
    listVrt(obj, requestID) {
        return Service.post(resource + "listVrt", obj, {
          params: { requestID: requestID },
        });
    }, 
    save(obj, requestID) {
        return Service.post(resource + "save", obj, {
          params: { requestID: requestID },
        });
    },     
    
}