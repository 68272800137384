<template>
    <div>
        <chamber-re-entry></chamber-re-entry>
    </div>
</template>
<script>
    
    import ChamberReEntry from './FrzReEntry.vue';
    import _sFrzChamberReEntry from "@/services/FrozenProduction/FrzChamberReEntry.js";

    export default{
        components: {ChamberReEntry},
        data(){
            return {
                
            }
        },
        watch: {
            
        },
        methods: {
            
        },
        mounted()
        {
            
        }
    }
</script>